<template>
  <div class="setting-crm">

    <base-top-menu :loading="saveAction"
      
      action-button-text="Сохранить" @cancelbutton="$router.back()" @actionbutton="saveChanges()">

      <template v-slot:header>
        <p class="body-l-semibold">
          Настройки CRM
        </p>
      </template>
      <template>
        <v-skeleton-loader :loading="loading" class="skeleton" :style="{ height: '100%', width: '100%' }"
          type="heading, text, image, actions">
          <v-row justify="center" no-gutters>
            <v-col :cols="12" :sm="12" :md="11" :lg="10" style="height: 100%">
              <client-fields ref="clientFields" :valid.sync="validSettFields" :fields.sync="internalSettFields"
                @save="saveChanges" />
              <nomenclature-fields ref="nomenFields" :valid.sync="validNomenFields" :fields.sync="internalNomenFields" @save="saveChanges" @SettChanges="hasSettChanges, true"/>
            </v-col>
          </v-row>
        </v-skeleton-loader>
      </template>
    </base-top-menu>
  </div>
</template>

<script>
import { deepClone, castingObjFields } from '@/utils'
import { mapGetters, mapActions } from 'vuex'
import ClientFields from './components/ClientFields'
import NomenclatureFields from './components/NomenclatureFields'

export default {
  components: {
    ClientFields,
    NomenclatureFields,
  },
  data() {
    return {
      loading: false,
      internalSettFields: [],
      internalNomenFields: [],
      validSettFields: true,
      validNomenFields: true,
      saveAction: false,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'programId',
    }),
    settingFields: {
      get() {
        return this.$store.getters['settings/account-fields/fields'][this.programId]
      },
      set(v) {
        this.$store.commit('settings/account-fields/SET_FIELDS', { programId: this.programId, payload: v })
      },
    },
    nomenFields: {
      get() {
        return this.$store.getters['settings/nomenclature-fields/fields'][this.programId]
      },
      set(v) {
        this.$store.commit('settings/nomenclature-fields/SET_FIELDS', { programId: this.programId, payload: v })
      },
    },
    hasSettChanges() {
      return this.checkChanges(this.settingFields, this.internalSettFields)
    },
    hasNomenChanges() {
      return this.checkChanges(this.nomenFields, this.internalNomenFields)
    },
  },
  watch: {
    programId(v) { if (v) this.fetch() },
  },
  async mounted() {
    await this.fetch()

    if (!this.internalSettFields.length) {
      this.$refs.clientFields.addField()
    }
    if (!this.internalNomenFields.length) {
      this.$refs.nomenFields.addField()
    }
  },
  methods: {
    ...mapActions({
      getSettFields: 'settings/account-fields/GetFields',
      createSettField: 'settings/account-fields/CreateField',
      updateSettField: 'settings/account-fields/UpdateField',
      reorderSettField: 'settings/account-fields/UpdateReOrder',
      // ========================= //
      getNomenFields: 'settings/nomenclature-fields/GetFields',
      createNomenField: 'settings/nomenclature-fields/CreateField',
      updateNomenField: 'settings/nomenclature-fields/UpdateField',
      reorderNomenField: 'settings/nomenclature-fields/UpdateReOrder',
    }),
    async fetch() {
      this.loading = true
      await this.getSettFields({ id: this.programId, withStub: false })
      await this.getNomenFields({ programId: this.programId, withStub: false })
      this.loading = false
      this.internalSettFields = deepClone(this.settingFields)
      this.internalNomenFields = deepClone(this.nomenFields)
    },
    async createOrUpdateSett() {
      let hasChange = false

      await Promise.all(
        this.internalSettFields.map(async isf => {
          if (isf.uuid && !isf.id) {
            hasChange = true
            isf.program_id = this.programId
            await this.createSettField(isf)
          } else {
            const sf = this.settingFields.find(sf => sf.id === isf.id)
            if (JSON.stringify(isf) !== JSON.stringify(sf)) {
              hasChange = true
              await this.updateSettField({ id: isf.id, payload: isf })
            }
          }
        }),
      )

      return hasChange
    },
    async reorderSett() {
      const ids = []
      const cloneFields = deepClone(this.settingFields)
      const newOrder = []

      this.internalSettFields.forEach((isf, idx) => {
        let id

        if (isf.id) id = isf.id
        else if (isf.uuid) {
          this.settingFields.forEach(sfd => {
            if (sfd.uuid === isf.uuid) {
              id = sfd.id
            }
          })
        }

        newOrder[idx] = cloneFields.find(csf => csf.id === isf.id)
        ids.push(id)
      })

      await this.reorderSettField({
        program_id: this.programId,
        field_ids: ids,
      })
      this.settingFields = newOrder
    },
    async createOrUpdateNomen() {
      let hasChange = false

      await Promise.all(
        this.internalNomenFields.map(async inf => {
          if (inf.uuid && !inf.id) {
            hasChange = true
            inf.program_id = this.programId
            await this.createNomenField(inf)
          } else {
            const sf = this.nomenFields.find(sf => sf.id === inf.id)
            if (JSON.stringify(inf) !== JSON.stringify(sf)) {
              hasChange = true
              await this.updateNomenField({ id: inf.id, payload: inf })
            }
          }
        }),
      )

      return hasChange
    },
    async reorderNomen() {
      const ids = []
      const cloneFields = deepClone(this.nomenFields)
      const newOrder = []

      this.internalNomenFields.forEach((isf, idx) => {
        let id

        if (isf.id) id = isf.id
        else if (isf.uuid) {
          this.nomenFields.forEach(sfd => {
            if (sfd.uuid === isf.uuid) {
              id = sfd.id
            }
          })
        }

        newOrder[idx] = cloneFields.find(csf => csf.id === isf.id)
        ids.push(id)
      })

      await this.reorderNomenField({
        program_id: this.programId,
        field_ids: ids,
      })
      this.nomenFields = newOrder
    },
    async saveChanges() {
      if (this.hasSettChanges) {
        await this.createOrUpdateSett()
        await this.reorderSett()
      }
      if (this.hasNomenChanges) {
        await this.createOrUpdateNomen()
        await this.reorderNomen()
      }

      this.$notify({
        title: 'Настройки CRM',
        text: 'Настройки CRM сохранены',
        type: 'success',
      })

      this.$router.back()
    },
    checkChanges(external, internal) {
      const existFields = !!(external && external.length)

      const externalIds = existFields
        ? external.map(sf => sf.id)
        : null
      const internalIds = internal.map(df => df.id)

      if (existFields) {
        const internalFields = internal
          .map(isf => castingObjFields(external[0], isf))

        return external.some(sf => {
          return !internalFields.some(f => JSON.stringify(sf) === JSON.stringify(f))
        }) ||
          JSON.stringify(externalIds) !== JSON.stringify(internalIds)
      } else {
        return !!internalIds.length
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
@import "@/styles/_typography.sass";

.setting-crm {
  .skeleton {
    .v-skeleton-loader__heading {
      margin-top: 70px;
      margin-bottom: 8px;
    }

    .v-skeleton-loader__image {
      margin-bottom: 6px;
      margin-top: 34px;
    }

    .v-skeleton-loader__actions {
      margin-top: 10px;
      margin-bottom: 34px;
      text-align: left;
    }
  }
}
</style>
