<template>
  <div class="setting-crm__form">
    <BaseMasterFieldBlock
      title="Дополнительные поля товаров и услуг"
      description="Здесь настраиваются дополнительные поля товаров и услуг."
    >
      <template v-slot:input>
        <v-expansion-panels
          v-model="panelOpens"
          class="expansion-panels"
          multiple
        >
          <vue-draggable
            ref="draggable"
            :value="fields"
            ghost-class="ghost"
            class="draggable__list"
            @change="changeDrag"
            @input="$emit('update:fields', $event)"
          >
            <v-expansion-panel
              v-show="showPanel"
              v-for="(field, idx) in fields"
              :key="idx"
            >
              <v-expansion-panel-header disable-icon-rotate>
                <template v-slot="{ open }">
                  <v-row no-gutters>
                    <v-col
                      class="left-block"
                      cols="4"
                    >
                      <v-icon
                        class="icon-drag"
                        color="neutral-400"
                      >
                        mdi-drag
                      </v-icon>
                      <v-icon
                        class="icon"
                        :class="{'icon-rotate': isPanelOpen(idx)}"
                        color="neutral-500"
                        size="21"
                      >
                        $iconify_feather-chevron-down
                      </v-icon>
                      <p
                        class="body-m-semibold mb-0"
                        style="white-space: nowrap;"
                      >
                        {{ (field.id ? `[${field.id}]&nbsp;` : '')+ field.name }}
                      </p>
                    </v-col>
                    <v-col
                      cols="8"
                      class="text--secondary"
                    >
                      <v-row
                        no-gutters
                        style="width: 100%"
                      >
                        <v-fade-transition>
                          <v-col
                            v-show="!open"
                            cols="6"
                          >
                            <p class="body-m-regular mb-0">
                              Тип: {{ types[field.type] }}
                            </p>
                          </v-col>
                        </v-fade-transition>
                        <v-fade-transition>
                          <v-col
                            v-show="!open && field.required"
                            cols="6"
                          >
                            <p
                              style="position: relative;"
                              class="body-m-regular mb-0"
                            >
                              <span
                                style="font-size: 20px;position: absolute;left: -8px;"
                                class="error--text"
                              >*</span>Обязательное
                            </p>
                          </v-col>
                        </v-fade-transition>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:actions>
                  <v-icon
                    v-if="typeof validsForms[idx] === 'boolean' && !validsForms[idx]"
                    style="margin-right: 10px;"
                    color="error"
                  >
                    $iconify_bx-bxs-error
                  </v-icon>
                  <v-icon
                    color="error"
                    @click.stop="removeField(idx)"
                  >
                    $iconify_feather-trash
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form
                  :ref="`formField-${idx}`"
                >
                  <div class="field-block">
                    <p class="body-m-semibold mb-0">
                      Название
                    </p>
                    <base-text-field
                      v-model="field.name"
                      :rules="nameRules"
                      error-style="custom"
                      validation-placement="top"
                      maxlength="255"
                      placeholder="Введите название поля"
                      @input="validAllFields"
                    />
                  </div>
                  <div class="field-block">
                    <p class="body-m-semibold mb-0">
                      Тип
                    </p>
                    <v-select
                      v-model="field.type"
                      :items="typesList"
                      item-value="id"
                      item-text="text"
                      placeholder="Выберите тип"
                      outlined
                      hide-details
                      :disabled="!!field.id"
                      @change="validAllFields"
                    />
                  </div>
                  <div
                    v-if="field.type === 'select'"
                    class="field-block"
                  >
                    <p class="body-m-semibold mb-0">
                      Значение
                    </p>
                    <v-combobox
                      v-model="field.items"
                      placeholder="Введите значения (минимум 2)"
                      :rules="[
                        v => !!v || 'Введите хотя бы одно значение',
                        v => (v && v.length > 1) || 'Мин. кол-во значений 2'
                      ]"
                      :return-object="false"
                      hide-selected
                      hint
                      label
                      no-data-text
                      item-value="name"
                      item-text="name"
                      multiple
                      persistent-hint
                      chips
                      outlined
                      hide-details
                      deletable-chips
                      clearable
                      @change="validAllFields"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <p class="body-m-regular neutral-700--text mb-0">
                                Нажмите <kbd>Enter</kbd> для добавления нового значения
                              </p>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </div>
                  <div class="field-block">
                    <p class="body-m-semibold mb-0">
                      Обязательное
                    </p>
                    <v-switch
                      v-model="field.required"
                      hide-details
                    />
                  </div>
                  <div class="field-block">
                    <p class="body-m-semibold mb-0">
                      Только для чтения
                    </p>
                    <v-switch
                      v-model="field.readonly"
                      hide-details
                    />
                  </div>
                  <div class="field-block">
                    <p class="body-m-semibold mb-0 align-self-start">
                      Описание
                    </p>
                    <base-text-area
                      v-model="field.description"
                      placeholder="Введите описание поля клиента"
                      hide-details
                    />
                  </div>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </vue-draggable>
        </v-expansion-panels>
      </template>
    </BaseMasterFieldBlock>
    <v-btn
      class="btn-new-field"
      color="primary"
      @click="addField"
    >
      <v-icon
        class="icon"
        color="neutral-100"
        size="21"
      >
        $iconify_feather-plus-circle
      </v-icon>
      <p class="body-m-semibold neutral-100--text mb-0">
        Создать поле
      </p>
    </v-btn>
    
  </div>
</template>

<script>
  import { trimLR } from '@/utils'
  import { mapActions } from 'vuex'
  import VueDraggable from 'vuedraggable'

  export default {
    components: {
      VueDraggable,
    },
    props: {
      valid: Boolean,
      fields: {
        type: Array,
        default: function () {
          return []
        },
      },
    },
    data () {
      return {
        showPanel: false,
        validsForms: {},
        panelOpens: [],
        types: {
          string: 'строка',
          text: 'текст',
          link: 'ссылка',
          float: 'дробное число',
          integer: 'целое число',
          boolean: 'логическое',
          date: 'дата',
          datetime: 'дата и время',
          select: 'список',
        },
      }
    },
    computed: {
      typesList () {
        const list = []
        for (const [key, val] of Object.entries(this.types)) {
          list.push({ id: key, text: val })
        }
        return list
      },
      
      nameRules () {
        return [
          v => !!v || 'Обязательно для заполнения',
          v => {
            return this.fields.filter(df => {
              return v.length &&
                trimLR(df.name).toLowerCase() === trimLR(v).toLowerCase()
            }).length <= 1 ||
              'Поле с данным именем существует'
          },
        ]
      },
    },
    created() {},
    mounted () {
    if (this.fields.length <= 1 && this.fields == '') {
      this.showPanel = false; 
    }
    else {
      this.showPanel = true;
    }
    
  },
    methods: {
      ...mapActions({
        deleteNomenField: 'settings/nomenclature-fields/DeleteField',
      }),
      async changeDrag (ev) {
        const oldIdx = this.panelOpens.findIndex(el => el === ev.moved.oldIndex)
        const newIdx = this.panelOpens.findIndex(el => el === ev.moved.newIndex)
        this.$nextTick()
        if (oldIdx !== -1) {
          this.$set(this.panelOpens, oldIdx, ev.moved.newIndex)
        }
        if (newIdx !== -1) {
          this.$set(this.panelOpens, newIdx, ev.moved.oldIndex)
        }
      },
      validAllFields () {
        this.$nextTick(() => {
          let isAllValid = true
          const refs = this.$refs
          this.fields.forEach(() => {
            const keys = []

            Object.keys(refs).forEach(refK => {
              if (/^formField/g.test(refK)) keys.push(refK)
            })

            for (let i = 0; i < keys.length; i++) {
              const key = keys[i]
              const refForm = refs?.[key]?.[0]

              if (!refForm) continue

              const valid = refForm.validate()

              this.$set(
                this.validsForms,
                Number(key.replace(/formField-/gi, '')),
                valid,
              )

              if (!valid) {
                isAllValid = false
              }
            }
          })

          this.$emit('update:valid', isAllValid)
        })
      },
      async addField () {
        if (this.showPanel == false) {
          this.showPanel = true;
          return
        }
        this.fields.push(
          {
            program_id: this.programId,
            uuid: this.$uuid(),
            name: '',
            description: '',
            type: 'string',
            required: false,
            readonly: false,
          },
        )
        this.$emit('update:valid', false)

        await this.$nextTick()

        const idx = this.fields.length - 1
        this.panelOpens.push(idx)

        setTimeout(() => {
          // this.validAllFields()
          this.$refs['formField-' + idx][0].$children[0].focus()
        }, 0)
      },
      async removeField (idx) {
        const field = this.fields[idx]
        if (field.id) await this.deleteNomenField(field)

        this.$emit(
          'update:fields',
          this.fields
            .filter(df => df.id !== field.id || df.uuid !== field.uuid),
        )

        this.validAllFields()
        
      },
      isPanelOpen (idx) {
        return this.panelOpens.findIndex(el => el === idx) !== -1
      },
    },
  }
</script>

<style lang="scss" scoped>
.setting-crm__form {
  width: 100%;
  height: 100%;
  max-height: 100%;
  .btn-delete-field {
    min-width: 0!important;
  }
  .draggable__list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    .left-block {
      display: flex;
      p {
        max-width: 600px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .icon {
        margin-right: 14px;
        transition: 0.2s ease-in-out;
      }
      .icon-drag {
        margin-left: -14px;
        margin-right: 13px;
      }
      .icon-rotate {
        transform: rotate(180deg)
      }
    }
    .field-block {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      p {
        margin-right: 16px;
      }
    }
  }
  .btn-new-field {
    margin-top: 34px;
    margin-bottom: 34px;
    .icon {
      margin-right: 8px;
    }
  }
}
</style>
